import React from 'react';
import './Header.css';
import Particles from 'react-particles-js';

const Header = () => {
    return (
        <div className="header">
            <h1>Alen Lukacevic<small>Full Stack Developer</small></h1>
            <Particles
                params={{
                    "particles": {
                        "line_linked": {
                            "color": "#FFFFFF"
                        },
                        "number": {
                            "value": 70
                        },
                        "size": {
                            "value": 3
                        }
                    },
                    "interactivity": {
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "repulse"
                            }
                        }
                    }
                }}
                style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'transparent'
                }}
            />

                <div className="wave wave1"></div>
                <div className="wave wave2"></div>
                <div className="wave wave3"></div>
                <div className="wave wave4"></div>
        </div>
    );
}

export default Header;