import React from 'react';
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import Skills from '../components/Skills';
import Cards from '../components/Cards';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

const App = () => {
    return (
        <div>
            {/* <Navbar /> */}
            <Header />
            <Skills />
            <hr style={{ marginTop: '50px', marginBottom: '50px', width: '70%' }} />
            <Cards />
            <hr style={{ marginTop: '30px', marginBottom: '50px', width: '70%' }} />
            <Contact />
            <hr style={{ marginTop: '50px', marginBottom: '50px', width: '70%' }} />
            <Footer />
        </div>
    );
}

export default App;
