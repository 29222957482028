import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <p class="text-muted">Site by Alen Lukacevic</p>
        </div>
    );
}

export default Footer;