import React from 'react';
import './Cards.css';
import Project2 from '../images/project2.jpg';
import Project3 from '../images/project3.jpg';
import Project4 from '../images/project4.jpg';
import Project5 from '../images/project5.jpg';

const Cards = () => {
    return (
        <div id="Projects">
            <div className="cards-text">
                <h1>Projects</h1>
            </div>
            <div className="card-box">
                <div className="box">
                    <div className="imgBox">
                        <img src={Project4} />
                    </div>
                    <div className="card-content">
                        <h6>Community Blog</h6>
                        <h6>Django</h6>
                        <p><span className="login-account"><strong>Username</strong>: testuser | <strong>Password</strong>: testing321 </span><span className="line-break"><a className="btn btn-info django-link" href="https://everyoneblogs.herokuapp.com/" target="_blank">Visit Site</a></span></p>
                    </div>
                </div>
                <div className="box">
                    <div className="imgBox">
                        <img src={Project5} />
                    </div>
                    <div className="card-content">
                        <h6>Q & A Website</h6>
                        <h6>Django</h6>
                        <p><span className="login-account"><strong>Username</strong>: testuser | <strong>Password</strong>: testing321 </span><span className="line-break"><a className="btn btn-info django-link" href="http://questionanswersite.herokuapp.com/" target="_blank">Visit Site</a></span></p>
                    </div>
                </div>
                <div className="box">
                    <div className="imgBox">
                        <img src={Project2} />
                    </div>
                    <div className="card-content">
                        <h6>Budget App</h6>
                        <h6>Vanilla JavaScript</h6>
                        <p><a className="btn btn-info js-link" href="https://budgetapplicationjs.netlify.app/" target="_blank">Visit Site</a></p>
                    </div>
                </div>
                <div className="box">
                    <div className="imgBox">
                        <img src={Project3} />
                    </div>
                    <div className="card-content">
                        <h6>YouTube Browser App</h6>
                        <h6>React</h6>
                        <p><a className="btn btn-info react-link" href="https://youtubeapiapp.netlify.app/" target="_blank">Visit Site</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cards;