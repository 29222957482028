import React from 'react';
import './Skills.css';

const Skills = () => {
    return (
        <div className="container" id="Skills">
            <div className="skills-text">
                <h1>Skills</h1>
            </div>
            <div class="skills-table">
                <div class="section">
                    <h2>Front End</h2>
                        <li>JavaScript</li>
                        <li>React</li>
                        <li>Redux</li>
                        <li>HTML/CSS</li>
                        <li>Bootstrap</li>
                </div>
                <div class="section">
                    <h2>Back End</h2>
                        <li>Python</li>
                        <li>Django</li>
                        <li>SQL</li>
                </div>
            </div>
        </div>
    );
}

export default Skills;
