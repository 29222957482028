import React from 'react';
import './Navbar.css';
import { Link, animateScroll as scroll } from 'react-scroll';

class Navbar extends React.Component {
    render() {
        return (
            <div className="sticky-top">
                <nav class="navbar navbar-expand-lg navbar-dark navigation">
                    <div className="container">
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNav">
                            <ul class="navbar-nav mx-auto">
                                <li class="nav-item">
                                    <Link class="nav-link" onClick={() => scroll.scrollToTop()}>Home</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" to="Skills" smooth={true} duration={1000}>Skills</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" to="Projects" smooth={true} duration={1000}>Projects</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" to="Contact" smooth={true} duration={1000}>Links</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default Navbar;
