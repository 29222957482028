import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className="container" id="Contact">
            <div className="contact-text">
                <h1>Links</h1>
            </div>
            <div className="contact-links"> 
                <div className="contact-link">
                    <a href="#">
                        <i class="fa fa-file fa-4x" aria-hidden="true"></i>
                        <h2>Resume</h2>
                    </a>
                </div>
                <div className="contact-link">
                    <a href="https://www.linkedin.com/in/alenlukacevic/" target="_blank">
                        <i class="fa fa-linkedin-square fa-4x" aria-hidden="true"></i>
                        <h2>LinkedIn</h2>
                    </a>
                </div>
                <div className="contact-link">
                    <a href="https://github.com/AlenLukacevic" target="_blank">
                        <i class="fa fa-github-square fa-4x" aria-hidden="true"></i>
                        <h2>GitHub</h2>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Contact;